/* #main-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
} */
/* .dropdown-content > div:hover {
  background-color: #3ec487;
  border-color: transparent;
  color: #fff;
} */
.checkcontainer {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkcontainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkcontainer:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkcontainer#lit input:checked ~ .checkmark {
  background-color: hsl(153, 53%,  53%);
}

.checkcontainer#chill input:checked ~ .checkmark {
  background-color: hsl(207, 61%,  53%);
}


/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkcontainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkcontainer .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  cursor: pointer;
  width: 100%;
  text-align: center;
  padding: 5px;
}

/* Style the accordion panel. Note: hidden by default */
.accordion-panel {
  padding: 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.table.is-borderless td, .table.is-borderless th {
  border: 0;
 }
